import css from 'styled-jsx/css';

export const styles = css`
    .Badges {
      display: flex;
      flex-direction: column;
      min-width: 48px;
      pointer-events: none;
      align-items: flex-start;
    }
    .Badges_withOpacity {
      opacity: .5;
    }
  `;
